import { ShoppingCartTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { Cart } from '../api/orderApi';

function BuyButton({ item }) {
    const [cart, setCart] = useRecoilState(Cart);

    return (
        <>
            <IconButton
                onClick={() => {
                    if (!cart.find(c => (
                        c.id === item.id
                    ))) {
                        setCart([item, ...cart]);
                    }
                }}
                title="Purchase Item"
            >
                <ShoppingCartTwoTone
                    item={item}
                />
            </IconButton>
        </>
    )
}

export default BuyButton;