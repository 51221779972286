import { ArrowBack } from '@mui/icons-material';
import { Box, Card, CardActions, CardContent, CardMedia, CircularProgress, IconButton, Typography } from '@mui/material';
import { Container } from '@mui/system';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BuyButton from '../cmp/BuyButton';
import { ArtworkApi, ArtworkStore } from '../api/artworkApi';
import { useRecoilValue } from 'recoil';

function Art() {
    const { id } = useParams(),
        artwork = useRecoilValue(ArtworkStore(id)),
        nav = useNavigate(),
        handleBack = () => {
            nav(-1);
        };

    return (
        <>
            <ArtworkApi />
            {
                artwork ?
                    <Container
                        maxWidth="lg"
                    >
                        <Card raised>
                            <CardMedia
                                alt={artwork.name}
                                component="img"
                                image={artwork.image}
                            />
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    {artwork.name}
                                </Typography>
                                <Typography paragraph>
                                    {
                                        artwork.isSold ? "Sold" : artwork.price ? `Price: $${Number(artwork.price).toLocaleString()}` : "Not for sale"
                                    }
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <IconButton onClick={handleBack}>
                                    <ArrowBack />
                                </IconButton>
                                {artwork.isSold || !artwork.price || <BuyButton item={artwork} />}
                            </CardActions>
                        </Card>
                    </Container> :
                    <Box
                        sx={{ display: "flex", width: 1 }}
                    >
                        <CircularProgress
                            sx={{ margin: "50px auto" }}
                        />
                    </Box>
            }
        </>
    )
}

export default Art;