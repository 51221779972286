import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Artwork, Changes, Create, Update, UpdateImage } from "../api/artworkApi";
import { useRecoilState, useRecoilValue } from 'recoil';
import { Type, TypeApi } from '../api/typeApi';
import { CancelTwoTone, SaveTwoTone } from '@mui/icons-material';
import { MenuItem, Stack } from '@mui/material';

const logo = "https://bytonya.blob.core.windows.net/images/CM Logo.png";

function ArtworkEditor({ data, onClose }) {
    const
        [item, setItem] = useState({ ...data }),
        [imagePreview, setImagePreview] = useState(item.image || logo),
        [changes, setChanges] = useRecoilState(Changes),
        [image, setImage] = useState(null),
        [artwork, setArtwork] = useRecoilState(Artwork),
        [isBusy, setIsBusy] = useState(false),
        types = useRecoilValue(Type),
        handleChange = e => {
            const key = e.target.name,
                value = e.target.value;

            updateItem(key, value);
        },
        handleBooleanChange = e => {
            const key = e.target.name,
                value = e.target.checked;

            updateItem(key, value);
        },
        handleImageChange = e => {
            let reader = new FileReader(),
                file = e.target.files[0];

            setImage(file);

            reader.onloadend = () => {
                setImagePreview(reader.result);
            };

            reader.readAsDataURL(file);
        },
        handleSubmit = e => {
            e.preventDefault();

            setIsBusy(true);

            if (changes.id) {
                if (image) {
                    UpdateImage(changes.id, image)
                        .then((href) => {
                            updateItem(image, href);

                            Update(changes)
                                .then((res) => {
                                    setArtwork(artwork.map(a => (
                                        a.id === res.id ? res : a
                                    )).sort((a, b) => {
                                        const aVal = a.name && a.name.toUpperCase(),
                                            bVal = b.name && b.name.toUpperCase();

                                        return aVal < bVal ?
                                            -1 :
                                            bVal < aVal ?
                                                1 :
                                                0;
                                    }));

                                    setIsBusy(false);
                                    onClose && onClose();
                                })
                                .catch(() => {
                                    setIsBusy(false);
                                    alert("Artwork update failed.");
                                });
                        })
                        .catch(() => {
                            setIsBusy(false);
                            alert("Image upload failed.");
                        });
                }
                else {
                    Update(changes)
                        .then((res) => {
                            setArtwork(artwork.map(a => (
                                a.id === res.id ? res : a
                            )).sort((a, b) => {
                                const aVal = a.name && a.name.toUpperCase(),
                                    bVal = b.name && b.name.toUpperCase();

                                return aVal < bVal ?
                                    -1 :
                                    bVal < aVal ?
                                        1 :
                                        0;
                            }));

                            setIsBusy(false);
                            onClose && onClose();
                        })
                        .catch(() => {
                            setIsBusy(false);
                            alert("Artwork update failed.");
                        });
                }
            }
            else {
                Create(changes)
                    .then((res) => {
                        if (image) {
                            UpdateImage(res.id, image)
                                .then((href) => {
                                    setArtwork([...artwork, {
                                        ...res, ...{
                                            image: href
                                        }
                                    }].sort((a, b) => {
                                        const aVal = a.name && a.name.toUpperCase(),
                                            bVal = b.name && b.name.toUpperCase();

                                        return aVal < bVal ?
                                            -1 :
                                            bVal < aVal ?
                                                1 :
                                                0;
                                    }));

                                    setIsBusy(false);
                                    onClose && onClose();
                                })
                                .catch(() => {
                                    setIsBusy(false);
                                    alert("Image upload failed.");
                                });
                        }
                        else {
                            setArtwork([...artwork, res]).sort((a, b) => {
                                const aVal = a.name && a.name.toUpperCase(),
                                    bVal = b.name && b.name.toUpperCase();

                                return aVal < bVal ?
                                    -1 :
                                    bVal < aVal ?
                                        1 :
                                        0;
                            });

                            setIsBusy(false);
                            onClose();
                        }
                    })
                    .catch(() => {
                        setIsBusy(false);
                        alert("Artwork creation failed.");
                    });
            }
        },
        updateItem = (key, value) => {
            const change = { [key]: value };

            updateValue(change);
        },
        updateValue = change => {
            setItem({ ...item, ...change });
            setChanges({
                ...(changes ||
                    (data.id ?
                        {
                            id: data.id
                        } :
                        data)), ...change
            });
        };

    useEffect(() => {
        return () => {
            setChanges(null);
        }
    }, [setChanges]);

    return (
        <>
            <TypeApi />
            <form
                onSubmit={handleSubmit}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: 1 }}
                >
                    <Grid
                        item
                        sm={12}
                    >
                        <TextField
                            autoFocus
                            fullWidth
                            label="Name"
                            name="name"
                            onChange={handleChange}
                            required={true}
                            type="text"
                            value={item.name}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <Stack
                            spacing={1}
                        >
                            <TextField
                                fullWidth
                                label="Price"
                                name="typeId"
                                onChange={handleChange}
                                required={true}
                                select
                                value={item.typeId}
                            >
                                {
                                    types &&
                                    types.map(t => (
                                        <MenuItem
                                            key={t.id}
                                            value={t.id}
                                        >
                                            {`${t.name} ($${t.price.toLocaleString()})`}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={item.isSold}
                                        name="isSold"
                                        onChange={handleBooleanChange}
                                    />}
                                    label="Sold?"
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={item.isHidden}
                                        name="isHidden"
                                        onChange={handleBooleanChange}
                                    />}
                                    label="Hide?"
                                />
                            </FormGroup>
                        </Stack>
                    </Grid>
                    <Grid item sm={6}>
                        <img alt="Preview" src={imagePreview} style={{ width: "100%" }} />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                    >
                        <TextField
                            fullWidth
                            inputProps={{ accept: "image/jpeg" }}
                            label="Photo"
                            name="file"
                            onChange={handleImageChange}
                            type="file"
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <Button
                                disabled={isBusy || !changes}
                                startIcon={<SaveTwoTone />}
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button
                                disabled={isBusy}
                                startIcon={<CancelTwoTone />}
                                onClick={onClose}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default ArtworkEditor;