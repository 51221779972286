import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Artwork, ArtworkApi } from "../../../api/artworkApi";
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Link, Stack, Typography } from "@mui/material";
import { DeleteTwoTone, EditTwoTone } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { TypeStore } from "../../../api/typeApi";
import { DataGrid } from "@mui/x-data-grid";
import TypeEditor from "../../../dialogs/TypeEditor";
import { Link as RLink } from "react-router-dom";
import DeleteTypeEditor from "../../../dialogs/DeleteTypeEditor";

const
    columns = [
        {
            field: "name",
            flex: 3,
            headerName: "Pricing Usage",
            renderCell: (item) => {
                return [
                    <Link
                        color="text.primary"
                        component={RLink}
                        to={`/admin/inventory/${item.row.id}`}
                    >
                        {item.row.name}
                    </Link>
                ];
            }
        },
        {
            field: "isSold",
            flex: 1,
            headerName: "Availability",
            renderCell: item => (
                item.row.isHidden ? "Hidden" : item.row.isSold ? "Sold" : "For Sale"
            )
        }
    ];

function PricingDetail() {
    const
        params = useParams(),
        item = useRecoilValue(TypeStore(params.id)),
        artwork = useRecoilValue(Artwork),
        [rows, setRows] = useState([]),
        [mode, setMode] = useState("summary"),
        [title, setTitle] = useState(""),
        handleClose = () => {
            setMode("summary");
        };

    useEffect(() => {
        if (params.id) {
            setMode("summary");
        }
    }, [params, setMode]);

    useEffect(() => {
        switch (mode) {
            case "delete":
                setTitle("Delete Pricing");
                break;
            case "edit":
                setTitle("Edit Pricing");
                break;
            default:
                setTitle(item.name);
                break;
        }
    }, [item, mode, setTitle]);

    useEffect(() => {
        if (artwork) {
            setRows(artwork.filter(a => (
                a.typeId === params.id
            )));
        }
    }, [artwork, params, setRows]);

    return (
        <>
            <ArtworkApi />
            {
                item &&
                <Card
                    key={params.id}
                    raised
                    sx={{ height: 1, display: "flex", flexDirection: "column" }}
                >
                    <CardHeader
                        title={title}
                    />
                    <CardActions>
                        <ButtonGroup>
                            <Button
                                onClick={() => {
                                    setMode("edit");
                                }}
                                startIcon={<EditTwoTone />}
                                title="Edit Pricing"
                                variant={mode === "edit" ? "contained" : "outlined"}
                            >
                                Edit
                            </Button>
                            <Button
                                disabled={!!rows.length}
                                onClick={() => {
                                    setMode("delete");
                                }}
                                startIcon={<DeleteTwoTone />}
                                title="Delete Pricing"
                                variant={mode === "delete" ? "contained" : "outlined"}
                            >
                                Delete
                            </Button>
                        </ButtonGroup>
                    </CardActions>
                    {
                        mode === "edit" ?
                            <CardContent>
                                <TypeEditor
                                    data={item}
                                    onClose={handleClose}
                                />
                            </CardContent> :
                            mode === "delete" ?
                                <DeleteTypeEditor
                                    data={item}
                                    onClose={() => {
                                        setMode("summary");
                                    }}
                                /> :
                                <>
                                    <CardContent
                                        sx={{ flexGrow: 1 }}
                                    >
                                        <Stack
                                            spacing={2}
                                            sx={{ height: 1 }}
                                        >
                                            <Stack
                                                direction="row"
                                                spacing={2}
                                            >
                                                <Box>
                                                    <Typography
                                                        color="text.secondary"
                                                        variant="body2"
                                                    >
                                                        Price
                                                    </Typography>
                                                    <Typography
                                                        variant="body"
                                                    >
                                                        {item.price.toLocaleString()}
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Typography
                                                        color="text.secondary"
                                                        variant="body2"
                                                    >
                                                        Shipping
                                                    </Typography>
                                                    <Typography
                                                        variant="body"
                                                    >
                                                        {item.shipping.toLocaleString()}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                            <Box
                                                sx={{ flexGrow: 1 }}
                                            >
                                                <DataGrid
                                                    autoPageSize
                                                    columns={columns}
                                                    density="compact"
                                                    hideFooterSelectedRowCount
                                                    rows={rows}
                                                />
                                            </Box>
                                        </Stack>
                                    </CardContent>
                                </>
                    }
                </Card>
            }
        </>
    )
}

export default PricingDetail;