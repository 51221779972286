export async function isAdmin() {
    const response = await fetch("/.auth/me");

    if (response.ok) {
        const res = await response.json(),
            user = res.clientPrincipal;

        if (user) {
            return user.userRoles.indexOf("sysadmin") > -1;
        }

        return false;
    }

    return false;
}