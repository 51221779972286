import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import React from 'react';

function NoSelection() {
    return (
        <Card raised >
            <CardHeader
                title="Item Detail"
            />
            <CardContent>
                <Typography
                    paragraph
                >
                    Select an item from the list to see the detail here.
                </Typography>
            </CardContent>
        </Card>
    );
}

export default NoSelection;