import { Card, CardContent, Stack } from "@mui/material";
import React from "react";
import { useLoaderData } from "react-router-dom";

function JsonViewer() {
    const data = useLoaderData();

    return (
        <Stack
            spacing={2}
        >
            {
                data.map(i => (
                    <Card
                        raise
                        sx={{ width: 1 }}
                    >
                        <CardContent
                            sx={{ width: 1 }}
                        >
                            <pre>
                                {JSON.stringify(i, null, 4)}
                            </pre>
                        </CardContent>
                    </Card>
                ))
            }
        </Stack>
    )
}

export default JsonViewer;