import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { useRecoilValue } from "recoil";
import { PromoApi, PromoStore } from "../../../api/promoApi";
import PromoEditor from "../../../dialogs/PromoEditor";
import { EditTwoTone } from "@mui/icons-material";

function PromoDetail() {
    const
        params = useParams(),
        item = useRecoilValue(PromoStore(params.id)),
        [mode, setMode] = useState("summary"),
        [title, setTitle] = useState(""),
        handleClose = () => {
            setMode("summary");
        };

    useEffect(() => {
        if (params.id) {
            setMode("summary");
        }
    }, [params, setMode]);

    useEffect(() => {
        switch (mode) {
            case "edit":
                setTitle("Edit Promo");
                break;
            default:
                setTitle(item.code);
                break;
        }
    }, [item, mode, setTitle]);

    return (
        <>
            <PromoApi />
            {
                item &&
                <Card
                    key={params.id}
                    raised
                >
                    <CardHeader
                        title={title}
                    />
                    <CardActions>
                        <ButtonGroup>
                            <Button
                                onClick={() => {
                                    setMode("edit");
                                }}
                                startIcon={<EditTwoTone />}
                                title="Edit Promo"
                                variant={mode === "edit" ? "contained" : "outlined"}
                            >
                                Edit
                            </Button>
                        </ButtonGroup>
                    </CardActions>
                    {
                        mode === "edit" ?
                            <CardContent>
                                <PromoEditor
                                    data={item}
                                    onClose={handleClose}
                                />
                            </CardContent> :
                            <>
                                <CardContent
                                    sx={{ flexGrow: 1 }}
                                >
                                    <Stack
                                        spacing={2}
                                    >
                                        <Box>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Promotion
                                            </Typography>
                                            <Typography
                                                variant="body"
                                            >
                                                {
                                                    item.discount && item.shipping ?
                                                        `${item.discount}% Discount, Free Shipping` :
                                                        item.discount ?
                                                            `${item.discount}% Discount` :
                                                            "Free Shipping"
                                                }
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Expires
                                            </Typography>
                                            <Typography
                                                variant="body"
                                            >
                                                {item.expiration}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </CardContent>
                            </>
                    }
                </Card>
            }
        </>
    )
}

export default PromoDetail;