import * as React from 'react';
import { useEffect, useState } from 'react';
import { FavoriteApi, Favorites, Pin, Unpin } from "../api/favoriteApi";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import { Container } from '@mui/system';
import { PushPin, ThumbUp, ZoomIn } from '@mui/icons-material';
import { isAdmin } from '../api/userApi';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';


function Home() {
    const
        [favorites, setFavorites] = useRecoilState(Favorites),
        [allowEdit, setAllowEdit] = useState(false),
        nav = useNavigate(),
        handlePin = (item, index) => {
            Pin(item.id, index)
                .then(() => {
                    updateItem(item.id, {
                        isPinned: true
                    });
                });
        },
        handleUnpin = (item) => {
            Unpin(item.id)
                .then(() => {
                    updateItem(item.id, {
                        isPinned: false
                    });
                });
        },
        updateItem = (id, update) => {
            setFavorites(favorites.map(item => {
                return item.id === id ?
                    { ...item, ...update }
                    : item;
            }));
        };

    useEffect(() => {
        isAdmin()
            .then(res => {
                if (res) {
                    setAllowEdit(true);
                }
            });
    }, [setAllowEdit]);

    return (
        <>
            <FavoriteApi />
            {
                favorites ?
                    <>
                        <Container
                            maxWidth="xl"
                            sx={{ display: { xs: 'none', md: 'block' } }}
                        >
                            <ImageList
                                cols={4}
                                gap={8}
                                rowHeight={300}
                                variant="quilted"
                                sx={{ height: 1, mt: 2, width: 1 }}
                            >
                                {
                                    favorites.map((item, index) => (
                                        <ImageListItem
                                            key={item.id}
                                            cols={item.cols}
                                            rows={item.rows}
                                        >
                                            <img
                                                alt={item.name}
                                                loading="lazy"
                                                src={item.image}
                                                style={{ cursor: "pointer", objectFit: item.fit }}
                                                onClick={() => {
                                                    nav(`/art/${item.id}`);
                                                }}
                                            />
                                            <ImageListItemBar
                                                title={item.name}
                                                actionIcon={
                                                    allowEdit ?
                                                        item.isPinned ?
                                                            <IconButton
                                                                onClick={() => {
                                                                    handleUnpin(item);
                                                                }}
                                                            >
                                                                <PushPin />
                                                            </IconButton>
                                                            :
                                                            <IconButton
                                                                onClick={() => {
                                                                    handlePin(item, index);
                                                                }}
                                                            >
                                                                <ThumbUp />
                                                            </IconButton>
                                                        :
                                                        <IconButton
                                                            onClick={() => {
                                                                nav(`/art/${item.id}`);
                                                            }}
                                                        >
                                                            <ZoomIn />
                                                        </IconButton>
                                                }
                                            />
                                        </ImageListItem>
                                    ))
                                }
                            </ImageList>
                        </Container>
                        <Box
                            sx={{ width: 1, display: { xs: 'block', md: 'none' } }}
                        >
                            <ImageList
                                cols={1}
                                sx={{ height: 1, mt: 2, width: 1 }}
                            >
                                {
                                    favorites.map((item, index) => (
                                        <ImageListItem
                                            key={item.id}
                                        >
                                            <img
                                                alt={item.name}
                                                loading="lazy"
                                                src={item.image}
                                                style={{ cursor: "pointer", objectFit: "cover" }}
                                                onClick={() => {
                                                    nav(`/art/${item.id}`);
                                                }}
                                            />
                                            <ImageListItemBar
                                                title={item.name}
                                                actionIcon={
                                                    allowEdit ?
                                                        item.isPinned ?
                                                            <IconButton
                                                                onClick={() => {
                                                                    handleUnpin(item);
                                                                }}
                                                            >
                                                                <PushPin />
                                                            </IconButton>
                                                            :
                                                            <IconButton
                                                                onClick={() => {
                                                                    handlePin(item, index);
                                                                }}
                                                            >
                                                                <ThumbUp />
                                                            </IconButton>
                                                        :
                                                        <IconButton
                                                            onClick={() => {
                                                                nav(`/art/${item.id}`);
                                                            }}
                                                        >
                                                            <ZoomIn />
                                                        </IconButton>
                                                }
                                            />
                                        </ImageListItem>
                                    ))
                                }
                            </ImageList>
                        </Box>
                    </> :
                    <Box
                        sx={{ display: "flex", width: 1 }}
                    >
                        <CircularProgress
                            sx={{ margin: "50px auto" }}
                        />
                    </Box>
            }
        </>
    )
}

export default Home;