import React from "react";
import { useEffect } from "react";
import { atom, selectorFamily, useRecoilState } from "recoil";

export const
    Type = atom({
        key: "Type",
        default: null
    }),
    TypeStore = selectorFamily({
        key: "TypeStore",
        get: (id) => ({ get }) => {
            const type = get(Type);

            return type &&
                type.find(t => (
                    t.id === id
                ));
        }
    });

export function TypeApi() {
    const [type, setType] = useRecoilState(Type);

    useEffect(() => {
        if (!type) {
            List()
                .then(result => {
                    setType(result);
                });
        }
    }, [type, setType]);

    return (
        <></>
    )
}

export async function Create(model) {
    const response = await fetch("/api/type", {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function Delete(id) {
    const response = await fetch(`/api/type/${id}`, {
        method: "DELETE"
    });

    if (response.ok) {
        return;
    }

    throw new Error(await response.text());
}

export async function Update(model) {
    const response = await fetch(`/api/type/${model.id}`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

async function List() {
    const response = await fetch("/api/type");

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}