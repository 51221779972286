import React, { useEffect } from "react";
import { AnonymousCredential, BlockBlobClient } from "@azure/storage-blob";
import { atom, selectorFamily, useRecoilState } from "recoil";

export const
    Artwork = atom({
        key: "Artwork",
        default: null
    }),
    ArtworkStore = selectorFamily({
        key: "ArtworkStore",
        get: (id) => ({ get }) => {
            const artwork = get(Artwork);

            return artwork &&
                artwork.find(i => (
                    i.id === id
                ));
        }
    }),
    Changes = atom({
        key: "Changes",
        default: null
    });

let token = null;

export function ArtworkApi() {
    const [artwork, setArtwork] = useRecoilState(Artwork);

    useEffect(() => {
        if (!artwork) {
            List()
                .then(result => {
                    setArtwork(result);
                });
        }
    }, [artwork, setArtwork]);

    return (
        <></>
    )
}

export async function Create(model) {
    const response = await fetch("/api/artwork", {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function Update(model) {
    const response = await fetch(`/api/artwork/${model.id}`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function UpdateImage(id, image) {
    await initToken();

    const fileName = `${id}.jpg`,
        href = `${token.url}/images/${fileName}`,
        login = `${href}?${token.sasKey}`,
        client = new BlockBlobClient(login, new AnonymousCredential());

    await client.uploadData(image);
    return href;
}

async function initToken() {
    if (token && token.expires > new Date()) {
        return;
    }

    const response = await fetch("/api/image/sas");

    if (response.ok) {
        token = await response.json();
        token.expires = new Date(token.expires);

        return;
    }

    throw new Error(await response.text());
}

async function List() {
    const response = await fetch("/api/artwork");

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}