import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useEffect } from 'react';
import { isAdmin } from '../api/userApi';
import { Link, useMatch } from 'react-router-dom';
import { MenuTwoTone, ShoppingCartTwoTone } from '@mui/icons-material';
import { useRecoilValue } from 'recoil';
import { Cart } from '../api/orderApi';
import { Badge } from '@mui/material';

const logo = "https://bytonya.blob.core.windows.net/images/CM Logo.png";

const NavBar = () => {
    const
        [anchorElNav, setAnchorElNav] = useState(null),
        isHome = useMatch("/"),
        isGallery = useMatch("/gallery/*"),
        isStore = useMatch("/store"),
        isAbout = useMatch("/about"),
        isAdminPage = useMatch("/admin/*"),
        [pages, setPages] = useState([]),
        cart = useRecoilValue(Cart);

    useEffect(() => {
        let list = [
            {
                name: 'Home',
                path: "/",
                active: isHome
            },
            {
                name: "Gallery",
                path: "/gallery",
                active: isGallery
            },
            {
                name: "Store",
                path: "/store",
                active: isStore
            },
            {
                name: "About",
                path: "/about",
                active: isAbout
            }
        ];

        isAdmin()
            .then(res => {
                if (res) {
                    list = list.concat([
                        {
                            name: "Admin",
                            path: "/admin",
                            active: isAdminPage
                        },
                        {
                            name: "Logout",
                            path: "/.auth/logout"
                        }
                    ]);
                }

                setPages(list);
            });
    }, [isHome, isGallery, isStore, isAbout, isAdminPage, setPages]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="sticky">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <a href="/">
                        <img alt="byTonya Logo" src={logo} style={{ margin: '10px', maxHeight: '128px' }} />
                    </a>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuTwoTone />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.name}
                                    onClick={() => {
                                        handleCloseNavMenu();
                                    }}
                                    component={page.name === "Logout" ? "a" : Link}
                                    to={page.path}
                                    href={page.path}
                                >
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                        {
                            cart.length ?
                                <>
                                    <div
                                        style={{ flexGrow: 1 }}
                                    />
                                    <IconButton
                                        component={Link}
                                        to="/cart"
                                    >
                                        <Badge
                                            badgeContent={cart.length}
                                            color="primary"
                                        >
                                            <ShoppingCartTwoTone />
                                        </Badge>
                                    </IconButton>
                                </> :
                                <></>
                        }
                    </Box>
                    <Container sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                variant={page.active ? "contained" : "text"}
                                sx={{ ml: 3, mr: 3, color: 'white', display: 'block' }}
                                component={page.name === "Logout" ? "a" : Link}
                                to={page.path}
                                href={page.path}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Container>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavBar;