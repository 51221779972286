import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Error from "./views/Error";
import Home from "./views/Home";
import Gallery from "./views/Gallery";
import Art from "./views/Art";
import About from "./views/About";
import Admin from "./views/Admin";
import Pricing from "./views/admin/Pricing";
import Promotions from "./views/admin/Promotions";
import NoSelection from "./views/admin/detail/NoSelection";
import ArtworkDetail from "./views/admin/detail/ArtworkDetail";
import PricingDetail from "./views/admin/detail/PricingDetail";
import Inventory from "./views/admin/Inventory";
import App from "./App";
import AdminIndex from "./views/admin/AdminIndex";
import PromoDetail from "./views/admin/detail/PromoDetail";
import JsonViewer from "./views/admin/JsonViewer";
import { ListOrders, ListCaptures } from "./api/orderApi";
import Checkout from "./views/Checkout";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Home />,
                errorElement: <Error />
            },
            {
                path: "about",
                element: <About />,
                errorElement: <Error />
            },
            {
                path: "admin",
                element: <Admin />,
                errorElement: <Error />,
                children: [
                    {
                        index: true,
                        element: <AdminIndex />
                    },
                    {
                        path: "inventory",
                        element: <Inventory />,
                        errorElement: <Error />,
                        children: [
                            {
                                index: true,
                                element: <NoSelection />,
                                errorElement: <Error />
                            },
                            {
                                path: ":id",
                                element: <ArtworkDetail />,
                                errorElement: <Error />
                            }
                        ]
                    },
                    {
                        path: "order",
                        element: <JsonViewer />,
                        loader: ListOrders,
                        errorElement: <Error />
                    },
                    {
                        path: "payment",
                        element: <JsonViewer />,
                        loader: ListCaptures,
                        errorElement: <Error />
                    },
                    {
                        path: "pricing",
                        element: <Pricing />,
                        errorElement: <Error />,
                        children: [
                            {
                                index: true,
                                element: <NoSelection />,
                                errorElement: <Error />
                            },
                            {
                                path: ":id",
                                element: <PricingDetail />,
                                errorElement: <Error />
                            }
                        ]
                    },
                    {
                        path: "promo",
                        element: <Promotions />,
                        errorElement: <Error />,
                        children: [
                            {
                                index: true,
                                element: <NoSelection />,
                                errorElement: <Error />
                            },
                            {
                                path: ":id",
                                element: <PromoDetail />,
                                errorElement: <Error />
                            }
                        ]
                    }
                ]
            },
            {
                path: "art/:id",
                element: <Art />,
                errorElement: <Error />
            },
            {
                path: "cart",
                element: <Checkout />,
                errorElement: <Error />
            },
            {
                path: "gallery",
                element: <Gallery />,
                errorElement: <Error />
            },
            {
                path: "store",
                element: <Gallery soldOnly />,
                errorElement: <Error />
            }
        ]
    }
]);

function Routes() {
    return (
        <RouterProvider
            router={router}
        />
    )
}

export default Routes;