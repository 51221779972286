import * as React from 'react';
import Fab from '@mui/material/Fab';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, CircularProgress, Stack, Toolbar } from '@mui/material';
import { AddTwoTone } from '@mui/icons-material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import AddDialog from '../../dialogs/AddDialog';
import { Promo, PromoApi } from '../../api/promoApi';
import PromoEditor from '../../dialogs/PromoEditor';

const
    columns = [
        {
            field: "code",
            flex: 1,
            headerName: "Promotions"
        }
    ];

function Promotions() {
    const
        promos = useRecoilValue(Promo),
        params = useParams(),
        nav = useNavigate(),
        [data, setData] = useState(null),
        handleAdd = () => {
            setData({
                id: null,
                code: null,
                discount: 0,
                expiration: new Date().toISOString().split("T")[0],
                shipping: 0
            });
        },
        handleClose = () => {
            setData(null);
        },
        handleSelect = (e) => {
            e.length && nav(e[0]);
        };

    return (
        <>
            <PromoApi />
            {
                promos ?
                    <Stack
                        spacing={1}
                        sx={{ height: 1 }}
                    >
                        <Toolbar
                            disableGutters
                        >
                            <Fab
                                color="primary"
                                onClick={handleAdd}
                            >
                                <AddTwoTone />
                            </Fab>
                        </Toolbar>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ flexGrow: 1, width: 1 }}
                        >
                            <Box sx={{ height: 1, width: 1 }}>
                                <DataGrid
                                    autoPageSize
                                    columns={columns}
                                    density="compact"
                                    hideFooterSelectedRowCount
                                    onRowSelectionModelChange={handleSelect}
                                    rows={promos}
                                    rowSelectionModel={params.id || []}
                                />
                            </Box>
                            <Box
                                sx={{ height: 1, width: 1 }}
                            >
                                <Outlet />
                            </Box>
                        </Stack>
                        <AddDialog
                            data={data}
                            editor={
                                <PromoEditor
                                    data={data}
                                    onClose={handleClose}
                                />
                            }
                            onClose={handleClose}
                            title="Promotion Editor"
                        />
                    </Stack> :
                    <Box
                        sx={{ display: "flex", width: 1 }}
                    >
                        <CircularProgress
                            sx={{ margin: "50px auto" }}
                        />
                    </Box>
            }
        </>
    )
}

export default Promotions;