import * as React from 'react';
import Fab from '@mui/material/Fab';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, CircularProgress, Stack, Toolbar } from '@mui/material';
import { AddTwoTone } from '@mui/icons-material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Type, TypeApi } from '../../api/typeApi';
import AddDialog from '../../dialogs/AddDialog';
import TypeEditor from '../../dialogs/TypeEditor';

const
    columns = [
        {
            field: "name",
            flex: 1,
            headerName: "Pricing"
        }
    ];

function Pricing() {
    const
        types = useRecoilValue(Type),
        params = useParams(),
        nav = useNavigate(),
        [data, setData] = useState(null),
        handleAdd = () => {
            setData({
                id: null,
                name: null,
                price: 0,
                shipping: 0
            });
        },
        handleClose = () => {
            setData(null);
        },
        handleSelect = (e) => {
            e.length && nav(e[0]);
        };

    return (
        <>
            <TypeApi />
            {
                types ?
                    <Stack
                        spacing={1}
                        sx={{ height: 1 }}
                    >
                        <Toolbar
                            disableGutters
                        >
                            <Fab
                                color="primary"
                                onClick={handleAdd}
                            >
                                <AddTwoTone />
                            </Fab>
                        </Toolbar>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ flexGrow: 1, width: 1 }}
                        >
                            <Box sx={{ height: 1, width: 1 }}>
                                <DataGrid
                                    autoPageSize
                                    columns={columns}
                                    density="compact"
                                    hideFooterSelectedRowCount
                                    onRowSelectionModelChange={handleSelect}
                                    rows={types}
                                    rowSelectionModel={params.id || []}
                                />
                            </Box>
                            <Box
                                sx={{ height: 1, width: 1 }}
                            >
                                <Outlet />
                            </Box>
                        </Stack>
                        <AddDialog
                            data={data}
                            editor={
                                <TypeEditor
                                    data={data}
                                    onClose={handleClose}
                                />
                            }
                            onClose={handleClose}
                            title="Pricing Editor"
                        />
                    </Stack> :
                    <Box
                        sx={{ display: "flex", width: 1 }}
                    >
                        <CircularProgress
                            sx={{ margin: "50px auto" }}
                        />
                    </Box>
            }
        </>
    )
}

export default Pricing;