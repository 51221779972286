import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function AdminIndex() {
    const nav = useNavigate();

    useEffect(() => {
        nav("/admin/inventory");
    }, [nav]);

    return (
        <></>
    )
}

export default AdminIndex;