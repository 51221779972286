import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Changes } from "../api/artworkApi";
import { useRecoilState } from 'recoil';
import { CancelTwoTone, SaveTwoTone } from '@mui/icons-material';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { Create, Promo, PromoApi, Update } from '../api/promoApi';

function PromoEditor({ data, onClose }) {
    const
        [item, setItem] = useState({ ...data }),
        [changes, setChanges] = useRecoilState(Changes),
        [promos, setPromos] = useRecoilState(Promo),
        [isBusy, setIsBusy] = useState(false),
        handleChange = e => {
            const key = e.target.name,
                value = e.target.value;

            updateItem(key, value);
        },
        handleBooleanChange = e => {
            const key = e.target.name,
                value = e.target.checked;

            updateItem(key, value);
        },
        handleNumberChange = e => {
            const key = e.target.name,
                value = parseFloat(e.target.value);

            updateItem(key, value);
        },
        handleSubmit = e => {
            e.preventDefault();

            setIsBusy(true);

            if (changes.id) {
                Update(changes)
                    .then((res) => {
                        setPromos(promos.map(p => (
                            p.id === res.id ? res : p
                        )));

                        setIsBusy(false);
                        onClose && onClose();
                    })
                    .catch(() => {
                        setIsBusy(false);
                        alert("Promotion update failed.");
                    });
            }
            else {
                Create(changes)
                    .then((res) => {
                        setPromos([...promos, res]);

                        setIsBusy(false);
                        onClose();
                    });
            }
        },
        updateItem = (key, value) => {
            const change = { [key]: value };

            updateValue(change);
        },
        updateValue = change => {
            setItem({ ...item, ...change });
            setChanges({
                ...(changes ||
                    (data.id ?
                        {
                            id: data.id
                        } :
                        data)), ...change
            });
        };

    useEffect(() => {
        return () => {
            setChanges(null);
        }
    }, [setChanges]);

    return (
        <>
            <PromoApi />
            <form
                onSubmit={handleSubmit}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: 1 }}
                >
                    <Grid
                        item
                        sm={6}
                    >
                        <TextField
                            autoFocus
                            fullWidth
                            label="Promo Code"
                            name="code"
                            onChange={handleChange}
                            required={true}
                            type="text"
                            value={item.code}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            label="Expiration"
                            name="expiration"
                            onChange={handleChange}
                            required={true}
                            type="date"
                            value={item.expiration}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            label="Discount %"
                            name="discount"
                            onChange={handleNumberChange}
                            required={true}
                            type="number"
                            value={item.discount}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <FormControlLabel
                            control={<Checkbox
                                checked={item.shipping}
                                name="shipping"
                                onChange={handleBooleanChange}
                            />}
                            label="Free Shipping?"
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <Button
                                disabled={isBusy || !changes}
                                startIcon={<SaveTwoTone />}
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button
                                disabled={isBusy}
                                startIcon={<CancelTwoTone />}
                                onClick={onClose}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default PromoEditor;