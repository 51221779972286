import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NavBar from './nav/NavBar';
import { Outlet } from "react-router-dom";
import { useRecoilValue } from 'recoil';
import { Cart } from './api/orderApi';
import { Box, Drawer, Stack } from '@mui/material';
import Checkout from './views/Checkout';

function App() {
  const
    theme = React.useMemo(
      () =>
        createTheme({
          palette: {
            mode: 'dark',
            primary: {
              main: '#827717'
            },
            secondary: {
              main: '#f57f17'
            }
          }
        }),
      []
    ),
    cart = useRecoilValue(Cart);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack
        spacing={2}
        sx={{ height: 1, pb: 2, width: 1 }}
      >
        <NavBar />
        <Box
          sx={{ flexGrow: 1, width: 1 }}
        >
          <Outlet />
        </Box>
      </Stack>
      {
        cart.length ?
          <Drawer
            anchor="right"
            open={true}
            sx={{ display: { xs: "none", md: "inherit" }, width: 300 }}
            variant="permanent"
          >
            <Box
              sx={{ width: 250 }}
            >
              <Checkout />
            </Box>
          </Drawer> :
          <></>
      }
    </ThemeProvider>
  );
}

export default App;
