import React, { StrictMode } from 'react';
import { RecoilRoot } from 'recoil';
import { createRoot } from "react-dom/client";
import Routes from './Routes';

createRoot(document.getElementById('root')).render(
    <StrictMode>
        <RecoilRoot>
            <Routes />
        </RecoilRoot>
    </StrictMode>
);
