import * as React from 'react';
import Fab from '@mui/material/Fab';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box, CircularProgress, Stack, ToggleButton, ToggleButtonGroup, Toolbar } from '@mui/material';
import { AddTwoTone } from '@mui/icons-material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Artwork, ArtworkApi } from '../../api/artworkApi';
import AddDialog from '../../dialogs/AddDialog';
import ArtworkEditor from '../../dialogs/ArtworkEditor';
import { useEffect } from 'react';

const
    columns = [
        {
            field: "name",
            flex: 1,
            headerName: "Inventory"
        }
    ];

function Inventory() {
    const
        artwork = useRecoilValue(Artwork),
        params = useParams(),
        nav = useNavigate(),
        [data, setData] = useState(null),
        [mode, setMode] = useState("all"),
        [rows, setRows] = useState([]),
        handleAdd = () => {
            setData({
                id: null,
                image: null,
                isHidden: false,
                isSold: false,
                name: null,
                typeId: null
            });
        },
        handleClose = () => {
            setData(null);
        },
        handleSelect = (e) => {
            e.length && nav(e[0]);
        };

    useEffect(() => {
        if (artwork) {
            switch (mode) {
                case "forSale":
                    setRows(artwork.filter(a => (
                        !a.isSold && !a.isHidden && !!a.typeId
                    )));

                    break;
                case "sold":
                    setRows(artwork.filter(a => (
                        a.isSold && !a.isHidden
                    )));

                    break;
                case "hidden":
                    setRows(artwork.filter(a => (
                        a.isHidden
                    )));

                    break;
                default:
                    setRows([...artwork]);

                    break;
            }
        }
    }, [mode, artwork, setRows]);

    return (
        <>
            <ArtworkApi />
            {
                artwork ?
                    <Stack
                        spacing={1}
                        sx={{ height: 1 }}
                    >
                        <Toolbar
                            disableGutters
                        >
                            <Fab
                                color="primary"
                                onClick={handleAdd}
                            >
                                <AddTwoTone />
                            </Fab>
                            <ToggleButtonGroup
                                exclusive
                                value={mode}
                                onChange={(_, value) => {
                                    setMode(value);
                                }}
                                sx={{ ml: 2 }}
                            >
                                <ToggleButton
                                    value="all"
                                >
                                    All
                                </ToggleButton>
                                <ToggleButton
                                    value="forSale"
                                >
                                    For Sale
                                </ToggleButton>
                                <ToggleButton
                                    value="sold"
                                >
                                    Sold
                                </ToggleButton>
                                <ToggleButton
                                    value="hidden"
                                >
                                    Hidden
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Toolbar>
                        <Stack
                            direction="row"
                            spacing={2}
                            sx={{ flexGrow: 1, width: 1 }}
                        >
                            <Box sx={{ height: 1, width: 1 }}>
                                <DataGrid
                                    autoPageSize
                                    columns={columns}
                                    density="compact"
                                    hideFooterSelectedRowCount
                                    onRowSelectionModelChange={handleSelect}
                                    rows={rows}
                                    rowSelectionModel={params.id || []}
                                />
                            </Box>
                            <Box
                                sx={{ height: 1, width: 1 }}
                            >
                                <Outlet />
                            </Box>
                        </Stack>
                        <AddDialog
                            data={data}
                            editor={
                                <ArtworkEditor
                                    data={data}
                                    onClose={handleClose}
                                />
                            }
                            onClose={handleClose}
                            title="Artwork Editor"
                        />
                    </Stack> :
                    <Box
                        sx={{ display: "flex", width: 1 }}
                    >
                        <CircularProgress
                            sx={{ margin: "50px auto" }}
                        />
                    </Box>
            }
        </>
    )
}

export default Inventory;