import { Box, Container, Grid, List, ListItemButton } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

function Admin() {
    const
        isInventory = useMatch("/admin/inventory/*"),
        isOrders = useMatch("/admin/order/*"),
        isPayments = useMatch("/admin/payment/*"),
        isPricing = useMatch("/admin/pricing/*"),
        isPromos = useMatch("/admin/promo/*"),
        [pages, setPages] = useState([]),
        nav = useNavigate();

    useEffect(() => {
        setPages([
            {
                name: "Inventory",
                path: "inventory",
                selected: isInventory,
                divider: true
            },
            {
                name: "Pricing",
                path: "pricing",
                selected: isPricing
            },
            {
                name: "Promotions",
                path: "promo",
                selected: isPromos,
                divider: true
            },
            {
                name: "PayPal Orders",
                path: "order",
                selected: isOrders
            },
            {
                name: "PayPal Payments",
                path: "payment",
                selected: isPayments
            }
        ])
    }, [setPages, isInventory, isOrders, isPayments, isPricing, isPromos]);

    return (
        <Grid
            container
            spacing={2}
            sx={{ height: 1 }}
        >
            <Grid
                item
                sx={{ height: 1 }}
                xs={1}
            >
                <List>
                    {pages.map(p => (
                        <ListItemButton
                            divider={!!p.divider}
                            key={p.path}
                            onClick={() => {
                                nav(p.path);
                            }}
                            selected={!!p.selected}
                        >
                            {p.name}
                        </ListItemButton>
                    ))}
                </List>
            </Grid>
            <Grid
                item
                sx={{ height: 1 }}
                xs={11}
            >
                <Container
                    maxWidth="lg"
                    sx={{ height: 1 }}
                >
                    <Box
                        sx={{ height: 1, width: 1 }}
                    >
                        <Outlet />
                    </Box>
                </Container>
            </Grid>
        </Grid>
    )
}

export default Admin;