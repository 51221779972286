import React, { useEffect } from "react";
import { atom, useRecoilState } from "recoil";

export const Favorites = atom({
    key: "Favorites",
    default: null
});

export function FavoriteApi() {
    const [favorites, setFavorites] = useRecoilState(Favorites);

    useEffect(() => {
        if (!favorites) {
            List()
                .then(result => {
                    setFavorites(result);
                })
        }
    }, [favorites, setFavorites]);

    return (
        <></>
    )
}

async function List() {
    const response = await fetch("/api/favorite");

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function Pin(id, index) {
    const response = await fetch(`/api/favorite/${id}/pin`, {
        method: "PUT",
        body: JSON.stringify({
            pinIndex: index
        })
    });

    if (response.ok) {
        return;
    }

    throw new Error(await response.text());
}

export async function Unpin(id) {
    const response = await fetch(`/api/favorite/${id}/unpin`, {
        method: "PUT"
    });

    if (response.ok) {
        return;
    }

    throw new Error(await response.text());
}
