import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

function AddDialog({ data, editor, title, onClose }) {
    return (
        <Dialog
            fullWidth={true}
            maxWidth="sm"
            onClose={onClose}
            open={!!data}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {editor}
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog;