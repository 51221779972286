import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Artwork, Changes } from "../api/artworkApi";
import { useRecoilState } from 'recoil';
import { Create, Type, TypeApi, Update } from '../api/typeApi';
import { CancelTwoTone, SaveTwoTone } from '@mui/icons-material';
import { Stack } from '@mui/material';

function TypeEditor({ data, onClose }) {
    const
        [item, setItem] = useState({ ...data }),
        [changes, setChanges] = useRecoilState(Changes),
        [types, setTypes] = useRecoilState(Type),
        [artwork, setArtwork] = useRecoilState(Artwork),
        [isBusy, setIsBusy] = useState(false),
        handleChange = e => {
            const key = e.target.name,
                value = e.target.value;

            updateItem(key, value);
        },
        handleNumberChange = e => {
            const key = e.target.name,
                value = parseFloat(e.target.value);

            updateItem(key, value);
        },
        handleSubmit = e => {
            e.preventDefault();

            setIsBusy(true);

            if (changes.id) {
                Update(changes)
                    .then((res) => {
                        setTypes(types.map(a => (
                            a.id === res.id ? res : a
                        )));

                        if (artwork) {
                            setArtwork(artwork.map(a => (
                                a.typeId === res.id ?
                                    {
                                        ...a, ...{
                                            price: res.price,
                                            shipping: res.shipping
                                        }
                                    } :
                                    a
                            )));
                        }

                        setIsBusy(false);
                        onClose && onClose();
                    })
                    .catch(() => {
                        setIsBusy(false);
                        alert("Pricing update failed.");
                    });
            }
            else {
                Create(changes)
                    .then((res) => {
                        setTypes([...types, res]);

                        setIsBusy(false);
                        onClose();
                    });
            }
        },
        updateItem = (key, value) => {
            const change = { [key]: value };

            updateValue(change);
        },
        updateValue = change => {
            setItem({ ...item, ...change });
            setChanges({
                ...(changes ||
                    (data.id ?
                        {
                            id: data.id
                        } :
                        data)), ...change
            });
        };

    useEffect(() => {
        return () => {
            setChanges(null);
        }
    }, [setChanges]);

    return (
        <>
            <TypeApi />
            <form
                onSubmit={handleSubmit}
            >
                <Grid
                    container
                    spacing={2}
                    sx={{ mt: 1 }}
                >
                    <Grid
                        item
                        sm={12}
                    >
                        <TextField
                            autoFocus
                            fullWidth
                            label="Name"
                            name="name"
                            onChange={handleChange}
                            required={true}
                            type="text"
                            value={item.name}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            label="Price"
                            name="price"
                            onChange={handleNumberChange}
                            required={true}
                            type="number"
                            value={item.price}
                        />
                    </Grid>
                    <Grid item sm={6}>
                        <TextField
                            fullWidth
                            label="Shipping"
                            name="shipping"
                            onChange={handleNumberChange}
                            required={true}
                            type="number"
                            value={item.shipping}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={12}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                        >
                            <Button
                                disabled={isBusy || !changes}
                                startIcon={<SaveTwoTone />}
                                type="submit"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button
                                disabled={isBusy}
                                startIcon={<CancelTwoTone />}
                                onClick={onClose}
                                variant="outlined"
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default TypeEditor;