import { Alert, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Capture, Cart, CartTotal, ClientId, ClientIdApi, Complete, Create, Discount } from "../api/orderApi";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useMatch, useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Artwork } from "../api/artworkApi";
import { useState } from "react";
import { Read } from "../api/promoApi";
import { useEffect } from "react";

function Checkout() {
    const
        [cart, setCart] = useRecoilState(Cart),
        setDiscount = useSetRecoilState(Discount),
        cartTotal = useRecoilValue(CartTotal),
        [isBusy, setIsBusy] = useState(false),
        [promoCode, setPromoCode] = useState(null),
        [errMessage, setErrMessage] = useState(null),
        [cartError, setCartError] = useState(null),
        nav = useNavigate(),
        [artwork, setArtwork] = useRecoilState(Artwork),
        clientId = useRecoilValue(ClientId),
        isCart = useMatch("/cart"),
        handleSubmitPromo = e => {
            e.preventDefault();

            setIsBusy(true);

            Read(promoCode)
                .then(result => {
                    setDiscount(result);
                })
                .catch(err => {
                    setErrMessage(err.message);
                })
                .finally(() => {
                    setIsBusy(false);
                });
        };

    useEffect(() => {
        if (isCart && !cart.length) {
            nav(-1);
        }
    }, [isCart, cart, nav]);

    return (
        <>
            <ClientIdApi />
            <Card
                raised
                sx={{ width: 1 }}
            >
                <CardHeader
                    title="Checkout"
                />
                <CardActions
                    sx={{ display: { xs: "inherit", md: "none" } }}
                >
                    <Button
                        onClick={() => {
                            nav(-1);
                        }}
                        variant="outlined"
                    >
                        Continue Shopping
                    </Button>
                </CardActions>
                {
                    cart.map(item => (
                        <CardContent
                            key={item.id}
                        >
                            <CardMedia
                                alt={item.name}
                                component="img"
                                image={item.image}
                                onClick={() => {
                                    nav(`/art/${item.id}`);
                                }}
                                sx={{
                                    cursor: "pointer"
                                }}
                            />
                            <Typography
                                onClick={() => {
                                    nav(`/art/${item.id}`);
                                }}
                                sx={{ cursor: "pointer" }}
                            >
                                {item.name}
                            </Typography>
                            <Typography
                                variant="body2"
                            >
                                {`Price: $${item.price.toLocaleString()}`}
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    setCart(cart.filter(i => (
                                        i.id !== item.id
                                    )))
                                }}
                                title="Remove"
                            >
                                <Delete />
                            </IconButton>
                        </CardContent>
                    ))
                }
                <CardContent>
                    <Box
                        sx={{ display: "flex" }}
                    >
                        <Typography
                            sx={{ display: "inline" }}
                            variant="body2"
                        >
                            Sub-Total
                        </Typography>
                        <div
                            style={{ flexGrow: 1 }}
                        />
                        <Typography
                            sx={{ display: "inline" }}
                            variant="body2"
                        >
                            {cartTotal.subTotal.toLocaleString()}
                        </Typography>
                    </Box>
                    <Box
                        sx={{ display: "flex" }}
                    >
                        <Typography
                            sx={{ display: "inline" }}
                            variant="body2"
                        >
                            Shipping
                        </Typography>
                        <div
                            style={{ flexGrow: 1 }}
                        />
                        <Typography
                            sx={{ display: "inline" }}
                            variant="body2"
                        >
                            {cartTotal.shipping.toLocaleString()}
                        </Typography>
                    </Box>
                    {
                        cartTotal.discount ?
                            <Box
                                sx={{ display: "flex" }}
                            >
                                <Typography
                                    sx={{ display: "inline" }}
                                    variant="body2"
                                >
                                    {cartTotal.promo}
                                </Typography>
                                <div
                                    style={{ flexGrow: 1 }}
                                />
                                <Typography
                                    sx={{ display: "inline" }}
                                    variant="body2"
                                >
                                    {cartTotal.discount.toLocaleString()}
                                </Typography>
                            </Box> :
                            <></>
                    }
                    <Box
                        sx={{ display: "flex" }}
                    >
                        <Typography
                            sx={{ display: "inline" }}
                        >
                            Total
                        </Typography>
                        <div
                            style={{ flexGrow: 1 }}
                        />
                        <Typography
                            sx={{ display: "inline" }}
                        >
                            {cartTotal.total.toLocaleString()}
                        </Typography>
                    </Box>
                </CardContent>
                {
                    cartTotal.promoId ?
                        <></> :
                        <CardContent>
                            <form
                                onSubmit={handleSubmitPromo}
                            >
                                <Stack
                                    direction="row"
                                    spacing={1}
                                >
                                    <TextField
                                        error={!!errMessage}
                                        helperText={errMessage}
                                        label="Promo Code"
                                        onChange={e => {
                                            setPromoCode(e.target.value);
                                        }}
                                        required
                                        sx={{ flexGrow: 1 }}
                                        type="text"
                                        value={promoCode}
                                    />
                                    <Button
                                        disabled={isBusy}
                                        type="sumbmit"
                                        variant="outlined"
                                    >
                                        Apply
                                    </Button>
                                </Stack>
                            </form>
                        </CardContent>
                }
                {
                    cartError && <CardContent>
                        <Alert
                            severity="error"
                        >
                            {cartError.message}
                        </Alert>
                    </CardContent>
                }
                {
                    clientId &&
                    <CardContent>
                        <PayPalScriptProvider
                            options={{
                                clientId: clientId
                            }}
                        >
                            <PayPalButtons
                                key={cartTotal.total}
                                createOrder={() => (
                                    Create({
                                        ...cartTotal, ...{
                                            items: [...cart]
                                        }
                                    })
                                        .then(result => {
                                            setCartError(null);
                                            return result.id;
                                        })
                                        .catch(err => {
                                            setCartError(err);
                                        })
                                )}
                                onApprove={(data) => {
                                    Capture(data)
                                        .then(() => {
                                            const items = cart.map(c => (
                                                c.id
                                            ));

                                            Complete(items)
                                                .then(() => {
                                                    setArtwork(artwork.map(a => (
                                                        items.indexOf(a.id) === -1 ?
                                                            a :
                                                            {
                                                                ...a,
                                                                ...{
                                                                    isSold: true
                                                                }
                                                            }
                                                    )));

                                                    setCartError(null);
                                                    setCart([]);
                                                    setDiscount(null);
                                                })
                                        })
                                        .catch(err => {
                                            setCartError(err);
                                        })
                                }}
                            />
                        </PayPalScriptProvider>
                    </CardContent>
                }
            </Card>
        </>
    )
}

export default Checkout;