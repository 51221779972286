import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import { Delete, Type } from "../api/typeApi";
import { useNavigate } from "react-router-dom";
import { Button, CardActions, CardContent, Typography } from "@mui/material";
import { CancelTwoTone, DeleteTwoTone } from "@mui/icons-material";

function DeleteTypeEditor({ data, onClose }) {
    const
        [isBusy, setIsBusy] = useState(false),
        [types, setTypes] = useRecoilValue(Type),
        nav = useNavigate(),
        handleSubmit = () => {
            setIsBusy(true);

            Delete(data.id)
                .then(() => {
                    setTypes(types.filter(t => (
                        t.id !== data.id
                    )));

                    nav(-1);
                })
                .finally(() => {
                    setIsBusy(false);
                    onClose && onClose();
                });
        };

    return (
        <>
            <CardContent>
                <Typography>
                    {`Are you sure you want to delete ${data.name}?`}
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    autoFocus
                    disabled={isBusy}
                    onClick={handleSubmit}
                    startIcon={<DeleteTwoTone />}
                    variant="contained"
                >
                    Delete
                </Button>
                <Button
                    onClick={onClose}
                    startIcon={<CancelTwoTone />}
                    variant="outlined"
                >
                    Cancel
                </Button>
            </CardActions>
        </>
    )
}

export default DeleteTypeEditor;