import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ArtworkStore } from "../../../api/artworkApi";
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, CardHeader, CardMedia, Stack, Typography } from "@mui/material";
import { EditTwoTone } from "@mui/icons-material";
import ArtworkEditor from "../../../dialogs/ArtworkEditor";
import { useRecoilValue } from "recoil";
import { Type, TypeApi } from "../../../api/typeApi";

function ArtworkDetail() {
    const
        params = useParams(),
        item = useRecoilValue(ArtworkStore(params.id)),
        types = useRecoilValue(Type),
        [type, setType] = useState(null),
        [mode, setMode] = useState("summary"),
        [title, setTitle] = useState(""),
        handleClose = () => {
            setMode("summary");
        };

    useEffect(() => {
        if (params.id) {
            setMode("summary");
        }
    }, [params, setMode]);

    useEffect(() => {
        if (mode === "edit") {
            setTitle("Edit Artwork");
        }
        else {
            setTitle(item.name);
        }

        setType(types && types.find(t => (
            t.id === item.typeId
        )));
    }, [item, mode, types, setTitle, setType]);

    return (
        <>
            <TypeApi />
            {
                item &&
                <Card
                    key={params.id}
                    raised
                    sx={{ height: 1, display: "flex", flexDirection: "column" }}
                >
                    <CardHeader
                        title={title}
                    />
                    <CardActions>
                        <ButtonGroup>
                            <Button
                                onClick={() => {
                                    setMode("edit");
                                }}
                                startIcon={<EditTwoTone />}
                                title="Edit Artwork"
                                variant={mode === "edit" ? "contained" : "outlined"}
                            >
                                Edit
                            </Button>
                        </ButtonGroup>
                    </CardActions>
                    {
                        mode === "edit" ?
                            <CardContent>
                                <ArtworkEditor
                                    data={item}
                                    onClose={handleClose}
                                />
                            </CardContent> :
                            <>
                                <CardContent>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                    >
                                        <Box>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                {type ? type.name : "Price"}
                                            </Typography>
                                            <Typography
                                                variant="body"
                                            >
                                                {type ? type.price.toLocaleString() : "N/A"}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                            >
                                                Availability
                                            </Typography>
                                            <Typography
                                                variant="body"
                                            >
                                                {item.isHidden ? "Hidden" : item.isSold ? "Sold" : "For Sale"}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                </CardContent>
                                <CardMedia
                                    alt={item.name}
                                    component="img"
                                    image={item.image}
                                    sx={{ flexGrow: 1, objectFit: "contain" }}
                                />
                            </>
                    }
                </Card>
            }
        </>
    )
}

export default ArtworkDetail;