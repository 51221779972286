import React, { useEffect } from "react";
import { atom, selector, useRecoilState } from "recoil";

export const
    Cart = atom({
        key: "Cart",
        default: []
    }),
    Discount = atom({
        key: "Discount",
        default: null
    }),
    ClientId = atom({
        key: "ClientId",
        default: null
    }),
    CartTotal = selector({
        key: "CartTotal",
        get: ({ get }) => {
            const cart = get(Cart),
                subTotal = cart.reduce((prev, { price }) => prev + price, 0),
                shipping = cart.reduce((prev, { shipping }) => prev + shipping, 0),
                promo = get(Discount);

            let discount = 0,
                total = subTotal + shipping;

            if (promo) {
                if (promo.discount) {
                    discount = subTotal * promo.discount / -100;
                }
                if (promo.shipping) {
                    discount -= shipping;
                }

                total += discount;
            }

            return {
                subTotal,
                shipping,
                discount: discount,
                total,
                promoId: promo ? promo.id : null,
                promo: promo ? promo.code : null
            };
        }
    });

export function ClientIdApi() {
    const [clientId, setClientId] = useRecoilState(ClientId);

    useEffect(() => {
        if (!clientId) {
            GetClientId()
                .then(result => {
                    setClientId(result);
                });
        }
    }, [clientId, setClientId]);

    return (
        <></>
    )
}

export async function Create(model) {
    const response = await fetch("/api/order", {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function Capture(model) {
    const response = await fetch("/api/order", {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function Complete(model) {
    const response = await fetch("/api/order", {
        method: "PATCH",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return;
    }

    throw new Error(await response.text());
}

async function GetClientId() {
    const response = await (fetch("/api/order/clientId"));

    if (response.ok) {
        return await response.text();
    }

    throw new Error(await response.text());
}
export async function ListCaptures() {
    const response = await fetch("/api/capture");

    if (response.ok) {
        const result = await response.json();

        return result.sort(() => (
            -1
        ));
    }

    throw new Error(await response.text());
}

export async function ListOrders() {
    const response = await fetch("/api/order");

    if (response.ok) {
        const result = await response.json();

        return result.sort(() => (
            -1
        ));
    }

    throw new Error(await response.text());
}
