import React from "react";
import { useEffect } from "react";
import { atom, selectorFamily, useRecoilState } from "recoil";

export const
    Promo = atom({
        key: "Promo",
        default: null
    }),
    PromoStore = selectorFamily({
        key: "PromoStore",
        get: (id) => ({ get }) => {
            const promo = get(Promo);

            return promo &&
                promo.find(p => (
                    p.id === id
                ));
        }
    });

export function PromoApi() {
    const [promo, setPromo] = useRecoilState(Promo);

    useEffect(() => {
        if (!promo) {
            List()
                .then(result => {
                    setPromo(result);
                });
        }
    }, [promo, setPromo]);

    return (
        <></>
    )
}

export async function Create(model) {
    const response = await fetch("/api/promo", {
        method: "POST",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function Read(code) {
    const response = await fetch(`/api/promo/${code}`);

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

export async function Update(model) {
    const response = await fetch(`/api/promo/${model.id}`, {
        method: "PUT",
        body: JSON.stringify(model)
    });

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}

async function List() {
    const response = await fetch("/api/promo");

    if (response.ok) {
        return await response.json();
    }

    throw new Error(await response.text());
}