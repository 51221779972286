import { Edit, ZoomIn } from '@mui/icons-material';
import { Box, Card, CardActions, CardContent, CardMedia, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { Container } from '@mui/system';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Artwork, ArtworkApi } from '../api/artworkApi';
import { isAdmin } from '../api/userApi';
import BuyButton from '../cmp/BuyButton';
import ArtworkEditor from '../dialogs/ArtworkEditor';
import { useRecoilValue } from 'recoil';
import AddDialog from '../dialogs/AddDialog';

function Gallery({ soldOnly } = false) {
    const
        artwork = useRecoilValue(Artwork),
        [rows, setRows] = useState([]),
        [edit, setEdit] = useState(null),
        [allowEdit, setAllowEdit] = useState(false),
        nav = useNavigate(),
        handleClose = () => {
            setEdit(null);
        };

    useEffect(() => {
        if (artwork) {
            if (soldOnly) {
                setRows(artwork.filter(item => (
                    !item.isHidden && !item.isSold && item.price
                )));
            }
            else {
                setRows(artwork.filter(item => (
                    !item.isHidden
                )));
            }
        }
    }, [artwork, soldOnly, setRows]);

    useEffect(() => {
        isAdmin()
            .then(res => {
                if (res) {
                    setAllowEdit(true);
                }
            })
    }, [setAllowEdit]);

    return (
        <>
            <ArtworkApi />
            {
                artwork ?
                    <>
                        <Container
                            maxWidth="xl"
                        >
                            <Grid container spacing={2}>
                                {
                                    rows.map(item => (
                                        <Grid
                                            item
                                            key={item.id}
                                            xs={12}
                                            sm={6}
                                            md={3}
                                        >
                                            <Card raised>
                                                <CardMedia
                                                    alt={item.name}
                                                    component="img"
                                                    image={item.image}
                                                    onClick={() => {
                                                        nav(`/art/${item.id}`);
                                                    }}
                                                    sx={{
                                                        cursor: "pointer",
                                                        height: {
                                                            xs: 300,
                                                            sm: 300,
                                                            md: 300,
                                                            lg: 360,
                                                            xl: 360
                                                        }
                                                    }}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        variant="h5"
                                                        component="h2"
                                                        sx={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            nav(`/art/${item.id}`);
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                    <Typography paragraph>
                                                        {
                                                            item.isSold ? "Sold" : item.price ? `Price: $${Number(item.price).toLocaleString()}` : "Not for sale"
                                                        }
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    {item.isSold || !item.price || <BuyButton item={item} />}
                                                    <IconButton
                                                        onClick={() => {
                                                            nav(`/art/${item.id}`);
                                                        }}
                                                    >
                                                        <ZoomIn />
                                                    </IconButton>
                                                    {allowEdit &&
                                                        <IconButton onClick={() => {
                                                            setEdit(item);
                                                        }}>
                                                            <Edit />
                                                        </IconButton>
                                                    }
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Container>
                        <AddDialog
                            data={edit}
                            onClose={handleClose}
                            title="Edit Artwork"
                            editor={
                                <ArtworkEditor
                                    data={edit}
                                    onClose={handleClose}
                                />
                            }
                        />
                    </> :
                    <Box
                        sx={{ display: "flex", width: 1 }}
                    >
                        <CircularProgress
                            sx={{ margin: "50px auto" }}
                        />
                    </Box>
            }
        </>
    )
}

export default Gallery;